import request from '@/utils/request'
export function getDcShoppingmallShoppingcart(params) {
  return request({
    url: '/dcShoppingmallShoppingcart',
    method: 'get',
    params
  })
}
export function getCustomerPaymentCode(params) {
  return request({
    url: '/dcShoppingmallShoppingcart/getCustomerPaymentCode',
    method: 'get',
    params
  })
}

export function addDcShoppingmallShoppingcart(data) {
    return request({
      url: '/dcShoppingmallShoppingcart',
      method: 'post',
      data
    })
  }
  export function editDcShoppingmallShoppingcart(data) {
    return request({
      url: '/dcShoppingmallShoppingcart',
      method: 'PUT',
      data
    })
  }

  export function deleteDcShoppingmallShoppingcart(id) {
    return request({
        url: '/dcShoppingmallShoppingcart/' + id,
        method: 'DELETE',
    })
}
export function addDcOrdercenterOrder(data) {
  return request({
    url: '/dcOrdercenterOrder',
    method: 'post',
    data
  })
}
export function addCustomerOrderPaymentImage(data) {
  return request({
    url: '/dcOrdercenterOrderPayment/addCustomerOrderPaymentImage',
    method: 'post',
    data
  })
}
export function addCustomerOrderLogisticsImage(data) {
  return request({
    url: '/dcOrdercenterOrderLogistics/addCustomerOrderLogisticsImage',
    method: 'post',
    data
  })
}
export function dcCustomercenterPaymentcodeQueryDefault(params) {
  return request({
      url: '/dcCustomercenterPaymentcode/queryDefault',
      method: 'get',
      params,
  })
}
export function getCustomerInfo(params) {
  return request({
      url: '/dcShoppingmallShoppingcart/getCustomerInfo',
      method: 'get',
      params,
  })
}
