<template>
  <div class="swiper-container" ref="cur">
    <div class="swiper-wrappers" style="height: 42vw;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;">
      <div class="swiper-slide" v-for="(silde, index) in ImgList" :key="index">
        <img :src="silde.imageUrl" :class="{ active: currentIndex == index }" @mouseover="changeCurrentIndex(index)" />
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
export default {
  name: 'ImageList',
  data() {
    return {
      currentIndex: 0,
      preview: ''
    }
  },
  props: {
    ImgList: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    ImgList() {
      this.$nextTick(() => {
        this.preview = new Swiper(this.$refs.cur, {
          // 如果需要前进后退按钮
          // 显示几个图片设置
          slidesPerView: 5,
          // 每一次切换图片个数
          slidesPerGroup: 1
        })
      })
    }
  },
  methods: {
    // 点击那张图片有背框颜色
    changeCurrentIndex(index) {
      this.currentIndex = index
      // 给zoom组件发索引值
      this.$emit('getIndex', index)
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper-container {



  box-sizing: border-box;
  padding-right: 11px;

  .swiper-wrapper {
    
  }

  .swiper-slide {
    width:7vw;
    height: 7vw;
    margin: 10px;

    img {
      width: 100%;
      height: 100%;
      border: 1px solid #ccc;
      padding: 2px;
     
      display: block;

      &.active {
        border: 2px solid #f60;
        padding: 1px;
      }
    }
  }
}
</style>
