import request from '@/utils/request'
export function hostGood(params) {
  return request({
    url: '/shoppingMallCommodity/getHotSellingPage',
    method: 'get',
    params
  })
}

export function getRecommendCommodityPage(params){
  return request({
    url: '/shoppingMallCommodity/getRecommendCommodityPage',
    method: 'get',
    params
  })
}
export function getNewCommodityPage(params){
  return request({
    url: '/shoppingMallCommodity/getNewCommodityPage',
    method: 'get',
    params
  })
}
 
export function getDcBasearchiveCommodity(params){
    return request({
      url: '/shoppingMallCommodity/getCommodityPage',
      method: 'get',
      params
    })
  }
  //在产
  export function getProductingCommodityPage(params){
    return request({
      url: '/shoppingMallCommodity/getProductingCommodityPage',
      method: 'get',
      params
    })
  }
  //查看在产详情
  export function getProductingCommodityPageDetail(params){
    return request({
      url: '/shoppingMallCommodity/getProductingCommodityDetail',
      method: 'get',
      params
    })
  }

  //在途
  export function getTransportCommodityPage(params){
    return request({
      url: '/shoppingMallCommodity/getTransportCommodityPage',
      method: 'get',
      params
    })
  }
   //查看在途详情
   export function getTransportCommodityPageDetail(params){
    return request({
      url: '/shoppingMallCommodity/getTransportCommodityDetail',
      method: 'get',
      params
    })
  }

  export function getDcBasearchiveCommodityPackingratio(params){
    return request({
      url: '/dcBasearchiveCommodityPackingratio',
      method: 'get',
      params
    })
  }
  export function getDcBasearchiveCommodityFile(params){
    return request({
      url: '/dcBasearchiveCommodityFile/getCommodityFile',
      method: 'get',
      params
    })
  }
  export function getDcOrdercenterOrder(params){
    return request({
      url: '/dcOrdercenterOrder',
      method: 'get',
      params
    })
  }
  export function queryCommodityUsageType(params){
    return request({
      url: '/shoppingMallCommodity/queryCommodityUsageType',
      method: 'get',
      params
    })
  }
  export function queryCommodityCategory(params){
    return request({
      url: '/shoppingMallCommodity/queryCommodityCategory',
      method: 'get',
      params
    })
  }
  export function queryCommoditySize(params){
    return request({
      url: '/shoppingMallCommodity/queryCommoditySize',
      method: 'get',
      params
    })
  }
  export function queryCommodityClass(params){
    return request({
      url: '/shoppingMallCommodity/queryCommodityClass',
      method: 'get',
      params
    })
  }
  export function getDcBasearchiveCommodityNew(params){
    return request({
      url: '/shoppingMallCommodity/getNewCommodityDetail',
      method: 'get',
      params
    })
  }
  

  // export function getDcBasearchiveCommodity(params){
  //   return request({
  //     url: '/shoppingMallCommodity/getCommodityPage',
  //     method: 'get',
  //     params
  //   })
  // }
  // http://192.168.1.15:353/api/dcBasearchiveCommodityPackingratio

